import React from 'react'

import { useTranslation } from 'next-i18next'

import { Typography } from '@mui/material'

import { Gender, Person } from 'src/@types/Person'

import useTypographyVariants from '../../hooks/useTypographyVariants'

export const Greeting = ({ person }: { person: Partial<Person> }) => {
  const variants = useTypographyVariants()

  const { t } = useTranslation()

  const title = (() => {
    if (person?.gender === Gender.Male) return t('b2c.general.mr')

    if (person?.gender === Gender.Female) return t('b2c.general.ms')

    return ''
  })()

  const salutation = (() => {
    const currentHour = new Date().getHours()

    if (currentHour >= 17 || currentHour < 5) return t('b2c.general.goodevening')

    if (currentHour >= 12) return t('b2c.general.goodafternoon')

    if (currentHour >= 5) return t('b2c.general.goodmorning')
  })()

  const name = (() => {
    if (person?.gender === Gender.Diverse) return `${person?.firstName} ${person?.lastName}`

    return person?.lastName
  })()

  return (
    <Typography variant={variants.main}>
      {salutation},{title ? ' ' : ''}
      {title}&nbsp;{name}!
    </Typography>
  )
}
