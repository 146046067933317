import { TypographyProps } from '@mui/material'

import useIsMobile from 'src/hooks/useIsMobile'

const useTypographyVariants = (): Record<string, TypographyProps['variant']> => {
  const isMobile = useIsMobile()

  return {
    main: `DM Serif H${isMobile ? 3 : 2}`,
    subheading: `Lato H${isMobile ? 5 : 4} Bold`,
  }
}

export default useTypographyVariants
